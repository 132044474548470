import React from 'react';

const About = () => {
  return (
    <div className="py-6 mt-12">
      <div className="container mx-auto px-6 md:px-8">
        <div className="flex justify-between items-center">
          <div className="text-sm">
            <p>&copy; {new Date().getFullYear()} <a href="https://psychedelicwater.com">Psychedelic Water</a> Inc. All rights reserved.</p>
          </div>
          <div className="flex space-x-4">
            <a href="/terms" className="text-sm hover:text-blue-400">
              Terms &amp; Conditions
            </a>
            <a href="/privacy" className="text-sm hover:text-blue-400">
              Privacy Policy
            </a>
            <a href="/contact" className="text-sm hover:text-blue-400">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
