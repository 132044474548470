import React, { useState } from 'react';
import axios from 'axios';

const API_KEY = '8611b385-b861-4f72-bf87-98dbf7c1dc58';
const OPEN_CAGE_API_KEY = '7f9683644e144d25ae978955a4113b0f';

const LegislatorSearch = ({ stateCode }) => {
  const [zipCode, setZipCode] = useState('');
  const [legislators, setLegislators] = useState([]);

  const fetchLatLngFromZipCode = async (zipCode, stateCode) => {
    try {
      const response = await axios.get(`https://api.opencagedata.com/geocode/v1/json`, {
        params: {
          key: OPEN_CAGE_API_KEY,
          q: `${zipCode}`,
          countrycode: 'us',
        },
      });

      const { lat, lng } = response.data.results[0].geometry;
      return { lat, lng };
    } catch (error) {
      console.error('Error fetching latitude and longitude from zip code', error);
    }
  };

  const searchLegislators = async () => {
    try {
      const { lat, lng } = await fetchLatLngFromZipCode(zipCode, stateCode);
      const response = await axios.get(`https://v3.openstates.org/people.geo`, {
        params: {
          apikey: API_KEY,
          lat: lat,
          lng: lng,
        },
      });

      setLegislators(response.data.results);
    } catch (error) {
      console.error('Error fetching legislators', error);
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    searchLegislators();
  };

  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Find Your Legislators</h2>
      <form onSubmit={handleSubmit} className="mb-4">
      <input
            type="text"
            placeholder="Enter your zip code"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            className="border border-gray-300 px-3 py-2 w-full"
            />
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-4 rounded"
        >
          Search
        </button>
      </form>
      <div className="grid grid-cols-1 gap-4">
        {legislators.map((legislator) => (
            <a href={legislator.openstates_url} target="_blank" rel="noreferrer">
                <div key={legislator.id} className="bg-white shadow-md rounded-md p-4">
                <div className="flex items-center mb-4">
                    <img
                    src={legislator.image}
                    alt={`${legislator.name}`}
                    className="w-16 h-16 object-cover object-center rounded-full mr-4"
                    />
                    <div>
                    <h3 className="text-xl font-semibold">{legislator.name}</h3>
                    <p className="text-gray-500">{legislator.jurisdiction.name} + {legislator.party} + {legislator.current_role.title} </p>
                    </div>
                </div>
                </div>
            </a>
        ))}
        </div>
    </div>
  );
};

export default LegislatorSearch;
