import React from "react";
import { useParams } from "react-router-dom";
import LegislatorSearch from './LegislatorSearch';
import GaugeChart from 'react-gauge-chart';

import stateData from "./stateData.json";

// const statuses = [
//     "No Legislative Activity",
//     "Local Government Reforms",
//     "Inactive or Failed Legislation",
//     "Active Legislation",
//     "Limited Judicial Exceptions",
//     "Reduced Penalty Statute",
//     "Decriminalization Statute",
//     "Working Group to Study Medical Use",
//     "Legalization & Regulation Statute "
// ];

function getGaugeChartPercentage(statusIndex, totalStatuses) {
    return (statusIndex + 1) / totalStatuses;
  }

const StateInfo = () => {
  const {stateCode} = useParams();
  const stateInfo = stateData.find((state) => state.code === stateCode);


  if (!stateInfo) {
    return <h2>State not found</h2>;
  }

  return (
    <div className="bg-gray-100 min-h-screen p-4">
    <div className="container mx-auto">
      <div className="flex items-center mb-6">
        <img src={stateInfo.crest} alt={`${stateInfo.name} Crest`} className="crest-image" />

        <h1 className="text-4xl font-bold">{stateInfo.name}</h1>
      </div>
      <div className="flex flex-col justify-center items-center bg-white shadow-md rounded-md p-4">
        <div className="">
            <p className="font-bold text-lg">Current Legislation Friendliness</p>
        </div>
        <div style={{ width: '200px' }}>
            <GaugeChart
            id="gauge-chart"
            nrOfLevels={9}
            percent={getGaugeChartPercentage(stateInfo.score, 10)}
            textColor="#222"
            needleColor="#222"
            colors={['#FF5F6D', '#FFC371', '#4BC0C0']}
            needleBaseColor="#222"
            arcPadding={0.02}
            hideText={true}
            />
        </div>
        <div>
            <p className="font-bold text-lg">{stateInfo.currentStatus}</p>
        </div>
        </div>



      <div className="mt-8">
        <h2 className="text-2xl font-semibold mb-4">Current Legal Status</h2>
        <div
          className="prose"
          dangerouslySetInnerHTML={{ __html: stateInfo.content }}
        ></div>
      </div>

      <div>
        <h1 className="text-xl font-semibold mb-4">Contact your representative</h1>
        <LegislatorSearch stateCode={stateCode} />
        </div>
      </div>
    </div>
  );
};


export default StateInfo;
