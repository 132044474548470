import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import StateInfo from "./StateInfo";
import Select from "react-select";
import Footer from './Footer';
import About from "./About";
import Terms from "./Terms";
import "./index.css";
import ReactGA from "react-ga4";


const usStates = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "District Of Columbia", value: "DC" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];

const Navbar = () => {
  return (

    <nav className="bg-white border-gray-200 dark:bg-gray-900">
  <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
    <a href="/" className="flex items-center">
        <img src="/logo.png" className="h-12 mr-3" alt="Psychedelic Legalization Logo" />
    </a>
    {/* <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
      <span className="sr-only">Open main menu</span>
      <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
    </button>
    <div className="hidden w-full md:block md:w-auto" id="navbar-default">
      <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
        <li>
          <a href="/about" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">About</a>
        </li>
      </ul>
    </div> */}
  </div>
</nav>

    // <div className="bg-white shadow-md">
    //   <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
    //     <nav className="flex">
    //       <a href="/" className="text-xl font-bold text-indigo-600 hover:text-indigo-900 mr-4">Psychedelic Legalization</a>
    //       {/* Add more links as required */}
    //     </nav>
    //   </div>
    // </div>
  )
}

function App() {

  ReactGA.initialize("G-9DYFYRMKS4");

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<HomePage/>} />
        <Route path="/:stateCode" element={<StateInfo/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/terms" element={<Terms/>} />
      </Routes>
      <Footer/>
    </Router>
  );
}

const HomePage = () => {

  const [state, setState] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate(`/${state}`);
  };

  const handleStateChange = (selectedOption) => {
    setState(selectedOption.value);
  };

  return (
    <div>
    <div className="min-h-scfreen bg-gray-100 flex items-center justify-left py-12 px-4 sm:px-6 lg:px-8 bg-blur">
      <div className="bg-white shadow-md rounded-lg p-8 w-full max-w-7xl lg:w-1/2">
        <div className="grid grid-cols-1 gap-4">
          <div>
            <h1 className="text-3xl font-bold mb-4 text-center">Psychedelic Legalization</h1>
            <p>
              The situation with psychedelics is changing rapidly. This site compiles the latest changes and interprets the legislation changes.
              Select the state you live in to get started.
            </p>
          </div>
          <div className="">
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label
                  htmlFor="state"
                  className="block text-sm font-medium text-gray-700"
                >
                  State
                </label>
                <Select
                  inputId="state"
                  name="state"
                  options={usStates}
                  onChange={handleStateChange}
                  required
                  className="mt-1 block w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full bg-indigo-600 text-white font-semibold py-2 px-4 rounded-md hover:bg-indigo-800"
                >
                  Get Informed
                </button>
              </div>
            </form>
          </div>
          </div>
          </div>
          </div>
      <div className="bg-white py-6">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-justify">
            <div>
              <h2 className="text-2xl font-bold mb-2">Who Are We</h2>
              <p>This website is sponsored by Psychedelic Water Inc. whose mission is to destigmatize psychedelics. People and resources have been provided to maintain and update this website from them. Psychedelic Water has invested in R&D to pursue the limits of commercially available psychedelics, and that expertise is leveraged for this website.</p>
            </div>
            <div>
              <h2 className="text-2xl font-bold mb-2">Source Verification</h2>
              <p>Links to the source legislation is provided for your reference and to verify any assessments we make about the state of legalization.</p>
            </div>
            <div>
              <h2 className="text-2xl font-bold mb-2">Regular Updates</h2>
              <p>Things are changing fast, we have alerts in place to identify new changes when they happen, and use AI to quickly digest the complex legal jargon and make it understandable. This keeps the website up to date and current.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
      )
    }

export default App;
