import React from 'react';

const Terms = () => {
  return (
    <div className="py-6 mt-12">
      <div className="container mx-auto px-6 md:px-8">
        <div className="flex justify-between items-center">
            <div className="text-sm">
            <h2>Terms &amp; Conditions</h2>
          <p>All information on this website is provided for entertainment purposes only and is not meant to be used as legal advice.  Always verify sources and consult a professional for legal advice</p>
          <p>Information is provided without warranty or guarantee</p>
          <h3>Data Collection:</h3>
            <p>Psychedelic Water Inc. does not collect any personal information from users of this website.</p>
            <p>Psychedelic Water uses Google analytics to understand the aggregate interest of information on this website</p>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
